@media only screen and (max-width: 1991px) {
  .rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
    min-width: 80px !important;
  }
  .quiz-table .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 100px !important;
  }
  /* .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 74px !important;
  } */
  .custom-table-div .rdt_TableCell {
    min-width: 70px !important;
  }

  .custom-table-div.notes-pending-table .rdt_TableCell:nth-child(6) {
    max-width: 200px !important;
  }
}
@media only screen and (max-width: 1750px) {
  .form-check-grps {flex-direction: column;align-items: inherit;padding-top: 0;}
  .rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
    min-width: 85px !important;
  }
  /* .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 72px !important;
  } */
  .quiz-table .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 100px !important;
  }
}

@media only screen and (min-width: 1500px) {



}

@media only screen and (min-width: 1200px) {

  

}

@media only screen and (max-width: 1500px) {
/* .dateTime {display: block;}
.dateTime.dateTime-small-table{display: flex;} */
.course-table-filter-sec .filter-search-bar-blk {flex-direction: column;}
/* .course-table-filter-sec .filter-search-bar-blk .add-ticket-blk {margin-top: 10px;} */
/* .course-table-filter-sec .system-administration-table.table-responsive {margin-top: 30px;} */
.course-table-filter-sec .system-administration-table.table-responsive.search-bar {margin-top: 20px !important;}
}

/* @media only screen and (max-width: 1191px) {
.dateTime.dateTime-small-table{display: block;}
} */
 
@media only screen and (max-width: 1599px) {
  .mycourses-ul-list li {
    width: -webkit-calc(100% / 5 - 10px);
    width: -moz-calc(100% / 5 - 10px);
    width: calc(100% / 5 - 10px);
  }
  .home-ul-list > li {
    width: -webkit-calc(100% / 5 - 10px);
    width: -moz-calc(100% / 5 - 10px);
    width: calc(100% / 5 - 10px);
  }
  .exam-ul-list > li {
    width: -webkit-calc(100% / 5 - 10px);
    width: -moz-calc(100% / 5 - 10px);
    width: calc(100% / 5 - 10px);
  }
  .rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
    min-width: 85px !important;
  }
  .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 72px !important;
  }
  .custom-table-div .rdt_TableCell {padding: 5px 5px;}
  .custom-table-div .rdt_TableCol {padding: 5px 5px !important;}

}
@media only screen and (max-width: 1399px) {
  .profile-left {
    max-width: 220px;
  }
  .profile-tab-pills .nav-link {
    padding: 15px 10px;
  }
  .profile-tab-pills .nav-link i {
    font-size: 20px;
    width: 30px;
    margin-right: 5px;
  }
  .profile-right {
    max-width: -webkit-calc(100% - 220px);
    max-width: -moz-calc(100% - 220px);
    max-width: calc(100% - 220px);
    margin-left: 220px;
  }
  .card.card-profile-info-card .card-body {
    padding: 10px;
  }
  .edit-profile-icon {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .edit-icon {
    min-height: 30px;
  }
  .card.card-profile-info-card .form-group > label,
  .theme-form .form-group > label {
    margin: 0 0 5px 0;
  }
  .card.card-profile-info-card .form-control,
  .theme-form .form-control {
    padding: 10px;
    min-height: 30px;
  }

  .form-icon-group select.form-control {
    padding: 0 5px !important;
    line-height: 42px !important;
  }
  .custom-select-box .css-g1d714-ValueContainer {
    padding: 0 8px;
  }
}
@media only screen and (max-width: 1299px) {
  .mycourses-ul-list li {
    width: -webkit-calc(100% / 4 - 10px);
    width: -moz-calc(100% / 4 - 10px);
    width: calc(100% / 4 - 10px);
  }
  .couses-bx-card .icon {
    width: 55px;
    height: 55px;
    font-size: 22px;
  }
  .home-ul-list > li {
    width: -webkit-calc(100% / 4 - 10px);
    width: -moz-calc(100% / 4 - 10px);
    width: calc(100% / 4 - 10px);
  }
  .exam-ul-list > li {
    width: -webkit-calc(100% / 4 - 10px);
    width: -moz-calc(100% / 4 - 10px);
    width: calc(100% / 4 - 10px);
  }

  .filter-search-bar-blk {
    position: relative;
    margin: 0;
    top: -20px;
    flex-wrap: wrap;
  }
  .filter-search-bar-blk .filter-button-group {
    flex-wrap: wrap;
  }
  .course-table-filter-sec .filter-search-bar-blk {flex-direction: inherit;}
  .form-check-grps .send-via-blk {
      display: block;
  }
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (max-width: 1199px) {
  .home-ul-list > li {
    width: -webkit-calc(100% / 3 - 10px);
    width: -moz-calc(100% / 3 - 10px);
    width: calc(100% / 3 - 10px);
  }
  #profile-tab-1 .col-lg-4,
  #profile-tab-3 .col-lg-4 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  #profile-tab-2 .col-lg-4 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .exam-ul-list > li {
    width: -webkit-calc(100% / 3 - 10px);
    width: -moz-calc(100% / 3 - 10px);
    width: calc(100% / 3 - 10px);
  }
  .cust_tbl_col_2 {
    min-width: 120px;
  }
  .cust_tbl_col_4 {
    min-width: 75px;
  }

  .user-add-blk.col-md-4.d-flex {
    display: block !important;
  }
  .user-add-blk-main.row.align-items-center {
    align-items: flex-end !important;
  }
  .user-add-blk .profile-box-2 {
    margin-bottom: 20px;
  }

  .form-grp {
    display: block;
    width: 100%;
  }
  .form-grp .form-control {
    max-width: 100%;
  }
  .form-grp .form-control + .form-control {
    margin-top: 20px;
  }
  .sub-detail-responsive .modal-update .flexible-modal {
    left: 30px !important;
    right: 30px;
    margin: auto;
    display: inherit !IMPORTANT;
    width:545px !important;
  }
  .sub-detail-responsive .post-by {
    padding: 0px 10px;
  }
  .sub-detail-responsive .modal-update .flexible-modal {
    left: 30px !important;
    right: 30px;
    margin: auto;
    display: inherit !IMPORTANT;
    width:545px !important;
  }
  .sub-detail-responsive  .scroll-sec-feed-msg  .post-by {
    padding: 0px 45px 0 0px;
  }

  .search-filter-div-left .filter-button-group>div+div {
    margin: 0px 0px 0px;
  }


  .system-bot-inner-R-body .btn-primary, .system-bot-inner-R-body .btn-save{    font-size: 12px;}


  .system-bot-inner-R-body .meeting-btns-list li{    min-width: auto;}

  .system-bot-inner-R-body .btn-group-box {
    width: 100%;
    margin-top: 20px;
    flex: initial;
    justify-content: flex-start;
}
.system-bot-inner-R-body .class-schedule {
  width: 100%;
  flex: initial;
}

.system-bot-inner-R-body{flex-wrap: wrap;}

}

@media only screen and (max-width: 1024px){
  .gradebook-expand-table .rdt_TableRow .rdt_TableCell,.gradebook-expand-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{width: 200px !important;display: inline-flex;}
.gradebook-expand-table.programme-grade-table-expand .rdt_TableRow .rdt_TableCell,.programme-grade-table-expand .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{width: 200px !important;display: inline-flex;}
}



@media only screen and (min-width: 992px) {
  .profile-left .close-btn-sidebar {
    display: none;
  }
  .user-profile .li-has-menu:hover .sub-menu {
    display: block !important;
  }
  .notfication-left-wrap .close-btn-sidebar {
    display: none;
  }
}
@media only screen and (max-width: 991px) { 
  .search-filter-div-left .filter-button-group {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .cke_top {
    max-height: 80px !important;
    overflow: auto !important;
  }
  .sub-detail-lft {
    left: -252px;
  }
  .sub-detail-rgt {
    max-width: 100%;
    left: 0;
    padding-left: 40px;
  }
  .close-btn-sidebar {
    right: -30px;
  }
  .close-btn-sidebar:before {
    content: "\f0c9";
  }
  body.hide-subject-sidebar .close-btn-sidebar:before {
    content: "\f00d";
  }
  body.hide-subject-sidebar .sub-detail-lft {
    left: 68px;
  }
  body.hide-subject-sidebar .close-btn-sidebar {
    right: 0;
  }
  .user-profile .dropdown-menu {
    min-width: 280px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .user-profile .li-has-menu .sub-menu {
    position: static;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #d7dee8;
    padding: 0;
    min-width: 100%;
  }
  .profile-left .close-btn-sidebar {
    display: block;
    right: -30px !important;
  }
  .profile-left {
    left: -152px;
    z-index: 1;
  }
  .profile-right {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding-left: 40px;
  }
  body.hide-subject-sidebar .profile-left.bg-white {
    left: 68px;
  }
  .profile-cover-top img {
    min-height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .portal-login {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -moz-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  body.hide-subject-sidebar .notfication-left-wrap {
    left: 68px;
  }
  .notfication-left-wrap .close-btn-sidebar {
    display: block;
    right: -40px;
  }
  .notfication-left-wrap {
    max-width: 290px;
    left: -245px;
    z-index: 1;
    -webkit-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    -moz-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  }
  .notfication-right-wrap {
    max-width: 100%;
    left: 0;
    padding: 20px;
  }
  .attachment-col {
    margin: 10px;
  }
  .attachment-row {
    margin: 0 -10px;
  }
  .exam-detail-lft {
    left: -252px;
  }
  .exam-detail-rgt {
    max-width: 100%;
    left: 0;
    padding-left: 40px;
  }
  body.hide-subject-sidebar .exam-detail-lft {
    left: 68px;
  }

  .filter-search-bar-blk {
    margin: 0;
    position: relative;
    top: -20px;
  }

  .ticket-details {
    height: 100%;
  }
  .Tickets-detail-body.row {
    flex-direction: column;
  }
  .Tickets-detail-body.row .col-md-3, .Tickets-detail-body.row .col-md-9 {
    max-width: 100%;
  }
  .ticket-details {
    margin-bottom: 20px;
  }
  .new-custom-login .container {
    height: 100vh;
    display: flex;
    align-items: center;
    min-width: 550px;
    justify-content: center;
  }
  .assigned-title-right .assigned-title-info {
    right: auto !important;
    left: auto !important;
    top: 65px;
  }
  .portal-login-card .card-footer a {
    color: #fff;
    font-size: 16px;
  }
  .portal-login-card .card-footer {
    padding: 20px;
  }
  .sub-detail-responsive  .row_icons_fas.ls_box{    
    flex-direction: column;
  }
  .sub-detail-responsive .row_icons_fas.ls_box {
      left: 100%;
      top: 4px;
  }
  .sub-detail-responsive .card-reply-box .row_icons_fas.ls_box {
      top: 55px;
  }
  .sub-detail-responsive .ls_box .post-reaction-icon,  .sub-detail-responsive .ls_box .important-icon,  .sub-detail-responsive .attachment-icon {
      margin-right: 0px !IMPORTANT;
      margin-bottom: 2px;
  }
  .assigned-title-info{left: auto;}
  .sub-detail-responsive .scroll-sec-feed-msg .post-by {
    padding: 0px 10px 0 0px;
  }

  .sub-menu-right-block .wrap-box-right{padding-left: 0;}

  .search-filter-div-left .system-administration-table.table-responsive {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
}

.search-filter-div-left .filter-scroll-inner {
  max-width: 100%;
  padding-left: 0;
  margin-top: 0px;
}

.search-filter-div-left .files-export-group {
  margin-top: 0;
}

.search-filter-div-right {
  margin-left: 5px;
  margin-top: 0px;
}

.search-filter-div-right .button-reset {
  margin-top: 0;
}

.search-filter-div-right {margin-left: 5px;}

.my-tickets-heading-blk{flex-wrap: wrap;}

.chat-date-time-box span {
  font-size: 12px;
}
.chat-date-time-box.mr-1 {
  padding-right: 20px;
}
.system-bot-inner-R-title{flex-wrap: wrap;}
.system-bot-inner-R-title h4{margin-bottom: 5px;}


}

@media only screen and (min-width: 768px) {
  .mobile-item {
    display: none;
  }
  .desktop-item {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .admin-home-acc .card-body{
    margin-right: 36px;
  }
  .logotext {
    font-size: 16px;
    margin-left: 5px;
  }
  .nav-ul > li > a {
    font-size: 18px;
  }
  .nav-ul > li + li {
    margin-left: 10px;
  }
  .user-profile .user-drop-down-img {
    width: 30px;
    height: 30px;
  }
  body.active-sidebar {
    overflow: hidden;
  }
  .sidebar-left {
    position: fixed;
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 9;
    bottom: 0;
    /* padding: 0 10px; */
  }
  .side-bar-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0;
    overflow: inherit;
    height: 100%;
  }
  .side-bar-nav > li > a {
    border-left: none;
    /* border-top: 4px solid #06172a; */
    padding: 13px 6px;
    min-width: 68px;
  }
  .side-bar-nav > li.active > a,
  .side-bar-nav > li > a.active,
  .side-bar-nav > li:hover > a {
    border-left: none;
    border-top: 4px solid #fff;
    height: 100%;
  }
  .card{margin-bottom: 30px;}
  body.active-sidebar .sidebar-left {
    left: 0px;
  }
  .sidebar-right {
    width: 100%;
    margin-left: 0;
  }
  .mycourses-ul-list li {
    width: -webkit-calc(100% / 2 - 10px);
    width: -moz-calc(100% / 2 - 10px);
    width: calc(100% / 2 - 10px);
  }
  .home-ul-list > li {
    width: -webkit-calc(100% / 2 - 10px);
    width: -moz-calc(100% / 2 - 10px);
    width: calc(100% / 2 - 10px);
  }
  .exam-ul-list > li {
    width: -webkit-calc(100% / 2 - 10px);
    width: -moz-calc(100% / 2 - 10px);
    width: calc(100% / 2 - 10px);
  }
  .sub-detail-lft {
    left: -320px;
  }
  body.hide-subject-sidebar .sub-detail-lft {
    left: 0px;
  }
  .sub-detail-lft .sub-lft-head,
  .sub-detail-lft .sub-lft-body {
    margin: 15px;
  }
  .sub-detail-lft .sub-lft-body {
    padding-bottom: 68px;
  }
  .sub-detail-lft .sub-lft-head {
    max-height: inherit;
  }
  .lecture-detail-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .lecture-detail-top-left {
    margin-bottom: 10px;
  }
  .lecture-tabs-wrap > .tab-content {
    padding: 20px 0 0 0;
    min-width: calc(100% + 15px);
    margin-left: -15px;
  }
  .media-icon.rounded-circle .user-profile-pic {
    width: 35px;
    height: 35px;
  }
  .post-wrap-header .media-icon.rounded-circle .user-profile-pic,
  .post-wrap > .post-wrap-body > .user-post-main > .media-icon.rounded-circle .user-profile-pic {
    width: 45px;
    height: 45px;
  }
  .media-icon.rounded-circle {
    margin-right: 10px;
  }
  .lecture-tabs-wrap .nav-pills {
    padding: 0 20px;
  }
  .comment-submit-bx textarea.form-control {
    font-size: 14px;
    padding: 8px 20px 8px 5px;
  }
  .post-wrap > .post-wrap-header .media-body.card {
    padding: 10px 25px 10px 10px;
  }
  .user-post-main > .media-body.card > .card-header,
  .user-post-main > .media-body.card > .card-footer {
    padding: 10px 25px 10px 10px;
  }
  .user-post-main > .media-body.card > .card-body > .user-post-reply {
    padding: 10px;
  }
  .dropdown-dots .btn {
    font-size: 22px;
    width: 20px;
    height: 20px;
  }
  .user-post-main > .media-body.card > .card-header h6 {
    font-size: 13px;
  }
  .user-post-main > .media-body.card > .card-header p {
    font-size: 15px;
  }
  .user-post-main > .media-body.card > .card-body > .user-post-reply h6 {
    font-size: 13px;
  }
  .user-post-main > .media-body.card > .card-body > .user-post-reply p {
    font-size: 15px;
  }
  .lecture-detail-top-right {
    padding: 10px 0;
    overflow-x: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .lec-dtl-rgt-ul li + li {
    min-width: 130px;
  }
  .your-complete-date {
    min-width: 175px;
  }
  .mobile-item {
    display: block;
  }
  .desktop-item {
    display: none;
  }
  /* .content-wrapper {
    max-height: -webkit-calc(100vh - 116px);
    max-height: -moz-calc(100vh - 116px);
    max-height: calc(100vh - 116px);
    height: -webkit-calc(100vh - 116px);
    height: -moz-calc(100vh - 116px);
    height: calc(100vh - 116px);
  } */
  .profile-left {
    left: -220px;
  }
  body.hide-subject-sidebar .profile-left.bg-white {
    left: 0px;
  }
  .profile-cover-top img {
    min-height: 120px;
  }
  .avtar-bot-lft .profile-img {
    width: 80px;
    height: 80px;
  }
  .avtar-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: -45px;
  }
  .avtar-bot-lft {
    margin: 0 auto 15px;
  }
  .avtar-bot-rgt {
    text-align: center;
    color: #484644;
    margin-bottom: 15px;
  }
  .portal-login-info h1 {
    font-size: 18px;
  }
  .portal-login-info h3 {
    font-size: 22px;
    margin: 15px 0 0 0;
  }
  .portal-login-info p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  .portal-login-card .card-body {
    padding: 20px;
  }
  button.btn.btn-white-bordered.btn-submit-info {
    padding: 8px 15px;
    font-size: 16px;
  }
  .notfication-left-wrap {
    left: -315px;
  }
  body.hide-subject-sidebar .notfication-left-wrap {
    left: 0;
  }
  .notfication-detail-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .notfication-head-rgt {
    max-width: 100%;
  }
  .notfication-head-rgt h3 {
    padding: 0;
    font-size: 15px;
    margin: 0 0 10px 0;
  }
  .notfication-head-rgt h3 .attachment-detail {
    position: static;
    display: block;
    margin: 10px 0 0;
  }
  .notfication-head-lft .icon {
    margin: 0 0 10px 0;
  }
  .notfication-detail-body .card-body .notify-sub-info li strong {
    display: block;
    margin: 0 0 5px 0;
  }
  .notfication-body {
    padding-bottom: 100px;
  }
  .exam-detail-lft {
    left: -320px;
  }
  body.hide-subject-sidebar .exam-detail-lft {
    left: 0px;
  }
  .cust_tbl_col_1 {
    min-width: 220px;
  }
  .cust_tbl_col_3 {
    min-width: 200px;
  }

  .main-action-btns.text-right .btn {
    margin: 3px !important;
  }

  .card.card-quiz-answer > .card-body {
    padding: 10px;
  }

  .card.card-quiz-answer > .card-body .table {
    min-width: 540px;
  }
  .admin-login-form .admin-login-form-head {
    padding: 50px 20px;
  }

  .sub-menu-content-block .sub-menu-left-block {
    left: -280px;
  }

  .hide-subject-sidebar .sub-menu-content-block .sub-menu-left-block {
    left: 0;
  }

  .sub-menu-left-block button.close-btn-sidebar {
    right: -36px;
  }
  .sub-menu-content-block .sub-menu-right-block {
    width: 100%;
  }

  .sub-menu-left-block button.close-btn-sidebar {
    display: block;
    top: 2px;
  }

  .filter-search-bar-blk {
    top: 0;
  }

  div.dataTables_wrapper div.dataTables_filter {
    text-align: inherit;
  }
  
  .card-header-img.card-header-text .header-text {
    flex-direction: column;
  }

  .card-header-text .header-text p {
    padding: 20px 0px 0px 0px;
    border: none;
    border-top: 2px solid #fff;
    margin: auto;
    max-width: inherit;
    text-align: center;
    font-size: 16px;
  }

  .card-header-text .header-text h4 {
    font: italic normal 900 90px/100px Segoe UI;
  }

  .new-custom-login .portal-login-card .card-body {
      padding: 20px;
  }
  
  .new-custom-login .card-footer {
    /* background-color: rgb(255 255 255); */
    padding: 0 20px 20px;
  }

  .new-custom-login .portal-login-card .card-footer a {
      font: normal normal 600 16px/22px Segoe UI;
  }

  .new-custom-login .portal-login-info p {
      font: normal normal normal 20px/28px Segoe UI;
  }
  .new-custom-login .container {min-width: auto;}


  .box-style-new2.box-2 {margin-bottom: 20px;height: auto;}
  .box-style-new3 {margin-bottom: 20px;}
  .sub-detail-responsive .modal-update .flexible-modal {
    width: 470px !important;
  }
  .user-post-main > .media-body.card > .card-header, .user-post-main > .media-body.card > .card-footer { padding: 10px 25px 10px 10px; }
  .image-popup-img img {
    max-width: 100%;
    max-height: calc(100vh - 100px);
  }
  .row_icons_fas.ls_box {
    flex-flow: column;
  }
  .ls_box .post-reaction-icon, .ls_box .important-icon {
    margin-bottom: 5px;
  }
  .scroll-sec-feed-msg  .row_icons_fas.ls_box{ top: 7px;}

  .sub-menu-right-block .wrap-box-right {
    padding-left: 0px;
}

.scroll-sec-feed-msg .post-by.user-profile.f-m-cont-main {
  padding-right: 20px;
  margin:0px 15px 15px 0px !important;
}

.scroll-sec-feed-msg .card-reply-box .card-right {
  margin-left: 0;
}
.scroll-sec-feed-msg .post-reat-new{ right: 12px;}

.new-discussion-box .post-by {
  padding-right: 28px;
}
.skelaton-custom{
  margin-right: 20px !important;
}
.media-icon.rounded-circle{
  margin-right: 16px;
}

.search-filter-div-left .filter-button-group>div+div {
  margin-top: 5px;
}

/* .search-filter-div-left .assessment-table-filter {
  margin: 5px 2px 0px;
} */

.search-filter-div-right {margin-top: 5px;margin-left: 0px;}

.search-filter-div-right .add-ticket-blk.button-reset.dropdown-comman {
  margin-left: 5px;
}

.search-filter-div-right .add-ticket-blk.button-reset.dropdown-comman {
  margin-left: 0;
  margin-top: 0px;
}

.sidebar-right {
  padding-top: 78px !IMPORTANT;
}
.ps-fd-card{
  max-height: calc(100vh - 280px);
}
}

@media(max-width:575px){
  .sub-detail-responsive .modal-update .flexible-modal {
    width: 85% !important;
  }
  .sub-detail-responsive   .iframe_modal_full .flexible-modal .body{ 
    height: 300px !important;
  }
  .sub-detail-responsive .post-by {
    padding: 0px 0px;
  }
  .sub-detail-responsive .ps-by-card-header h5 a{ 
    padding-left: 0;
  }
  .sub-detail-responsive .card-right .comment-con {
    display: flex;
    flex-wrap: wrap;
  }
  .sub-detail-responsive .comment-con{
    flex-wrap: wrap;
  }
  /* .sub-detail-responsive .likeUnlikeBox .post-reaction-blk {
    position: static;
    right: 15px;
    top: 0;
    padding-left: 10px;
    padding-bottom: 18px;
  } */
  .sub-detail-responsive .row_icons_fas.ls_box {
    left: 100%; 
  }
  .sub-detail-responsive .card-reply.card-reply-box {
    padding: 15px 15px 5px;
  }
  .sub-detail-responsive  .post-input-content .rdw-editor-wrapper .rdw-editor-main a.rdw-mention-link {
    font-size: 14px;
  }
  .sub-detail-responsive .files-list-blk.files-container .files-container-ul .files-container-li{    
    min-width: 100%;
  }
  .sub-detail-responsive .modal-update .flexible-modal {
    width: 85% !important;
  }
  .sub-detail-responsive   .iframe_modal_full .flexible-modal .body{ 
      height: 300px !important;
  }
  .sub-detail-responsive .other-reply {
    padding: 15px 20px 5px;}
  .comment-con  .new-comment{ 
    margin-left: 0;
    margin-top: 3px;
  }

  .my-tickets-heading-blk .d-flex.align-items-center {
    flex-wrap: wrap;
}

.system-bot-inner-R-body .btn-primary, .system-bot-inner-R-body .btn-save {
  font-size: 12px;
  line-height: 27px;
  height: 30px;
}

 
}

@media only screen and (max-width: 567px) {
  .ps-fd-card {
    height: calc(100vh - 325px);
    max-height: calc(100vh - 325px);
  }
  .logotext {
    display: none;
  }
  .home-ul-list > li {
    width: -webkit-calc(100% / 1 - 10px);
    width: -moz-calc(100% / 1 - 10px);
    width: calc(100% / 1 - 10px);
  }
  .home-bx-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    text-align: left;
    height: 100%;
  }
  .home-bx-card .icon {
    margin: 0 10px 0 0;
    width: 60px;
  }
  .home-bx-card-info {
    width: 100%;
  }
  .home-bx-card h3 {
    margin: 0 0 10px 0;
  }
  .home-bx-card p {
    margin: 0;
  }
  .home-bx-card p br {
    display: none;
  }

  .sub-detail-lft {
    left: -305px;
    max-width: 300px;
  }

  .my-tickets-heading-blk {
    flex-direction: column;
    align-items: inherit;
    /* margin-bottom: 20px !important; */
  }
  .my-tickets-heading-blk .landing-heading {
    margin-bottom: 8px !important;
  }
  .filter-search-bar-blk {
    display: inline-block;
  }

  .profile-box-2 article {
    display: block;
  }
  .profile-box-2 article figure {
    margin-bottom: 20px;
  }
  .new-custom-login .card-header-img.card-header-text img {
      height: auto;
      max-width: 75%;
  }
  .new-discussion-box .rdw-editor-toolbar,.new-reply-box .rdw-editor-toolbar{padding-right: 75px !important;}

  .new-reply-box .card-right {
    max-width: 100% !important;
    margin-left: 10px !important;
}


.logotext {
  font-size: 14px !important;

}

.sub-menu-left-block button.close-btn-sidebar {
  right: -83px;
  top: 28px !IMPORTANT;
}
.my-tickets-heading-blk.flex-direction.mb-2 {
padding-left: 0px;
}

.my-tickets-heading-blk .text-md-right.action2-blk{    display: flex;
justify-content: flex-end;
flex: 1;}

.my-tickets-heading-blk .text-md-right.action2-blk {
  display: flex;
  justify-content: flex-start;
  flex: initial;
  margin-left: 0;
  padding-left: 5px;
  margin-left: 5px;
}



ul.class-schedule-list.mt-2 {
  flex-wrap: wrap;
}

ul.class-schedule-list li {
  width: 100%;
}
  .search-bar-container{width: 100%;}
  .feed-search-bar{width: 100%;}  
  .feed-filter-dropdown{right: 0; top:45px;}
  .filter-item-container{grid-template-columns:auto;}  
}
@media only screen and (max-width: 467px) {
  .mycourses-ul-list li {
    width: -webkit-calc(100% / 1 - 10px);
    width: -moz-calc(100% / 1 - 10px);
    width: calc(100% / 1 - 10px);
  }
  .exam-ul-list > li {
    width: -webkit-calc(100% / 1 - 10px);
    width: -moz-calc(100% / 1 - 10px);
    width: calc(100% / 1 - 10px);
  }
  .new-custom-login .card-header-img.card-header-text img {
    height: auto;
  }
  .portal-login-card .card-footer a {
    color: #fff;
    font-size: 14px;
  }
  /* Oct 28 Manisha */
  .scroll-sec-feed-msg .post-by-right{max-width:100%}
  .sub-detail-responsive .scroll-sec-feed-msg .other-reply{padding:10px 25px 5px 10px}
  .scroll-sec-feed-msg .card-right{margin-left:0; flex: 1; max-width: 100%;}
  .scroll-sec-feed-msg .discuss-text iframe{width:100%}
  .scroll-sec-feed-msg .show-more-div{height: 47px;}
  .user-drop-down-img.desktop-user-img{display:none}
  .scroll-sec-feed-msg .mob-profile-right .ps-by-card-header  h5 span{font-size:12px; margin-left: 0;}
  .scroll-sec-feed-msg .ps-by-card-header h5 span{font-size:12px}
  .scroll-sec-feed-msg .post-reat-new{ right: 12px;}
  .post-subject-bold{ font-size: 14px;}
  .sub-detail-responsive .scroll-sec-feed-msg .comment-con {flex-wrap: nowrap;align-items: flex-start;}
  .scroll-sec-feed-msg  .other-reply .comment-con i{ margin-top: 2px;}
  .sub-detail-responsive  .scroll-sec-feed-msg .card-reply.card-reply-box .profile-title-box {
      padding-left: 0;
  }
  .sub-detail-responsive  .assigned-title-info article figcaption p{display: flex;
    align-items: center;
  }
  .sub-detail-responsive   .scroll-sec-feed-msg    .assigned-title-info article figcaption a {     
    font-weight: 400;
  }
  .scroll-sec-feed-msg   .assigned-title-info article figcaption i{
    padding-top: 4px;
  }
  .sub-detail-responsive   .scroll-sec-feed-msg    .assigned-title-info article figcaption  i.fal.fa-envelope {
    padding-right: 4px;
  }
  .scroll-sec-feed-msg  .post-reaction-blk {
    margin-top: 5px;
    margin-bottom: 4px;
    display: flex;
    position: static;
}
.mob-profile {
    display: block;
}
.scroll-sec-feed-msg .post-reaction-blk i{ 
    margin-right: 0;
}
.scroll-sec-feed-msg  .card-left {
    width: 30px;
}
.profile-title-box {
    flex-direction: column;
}
.scroll-sec-feed-msg .likeUnlikeBox .card-right {
    margin-left: 10px;
}
.scroll-sec-feed-msg  .post-subject-bold{    
    font-size: 16px;
}
.scroll-sec-feed-msg  .discuss-text p {
    font-size: 14px !important;
    margin-bottom: 10px !important;
}
.scroll-sec-feed-msg .post-reaction-blk a i {
    margin-right: 5px;
}
.mob-profile-right {
    display: flex;
    align-items: center;
    position: relative;
}
.scroll-sec-feed-msg .row_icons_fas.ls_box.desk-post-static {
    display: none;
}
.scroll-sec-feed-msg  .row_icons_fas.ls_box.notification-important{ top: -15px;}
/* .scroll-sec-feed-msg .row_icons_fas.ls_box.mob-discuss-icons.mob-post-static {
    position: static;
} */
/* .scroll-sec-feed-msg .row_icons_fas.ls_box.mob-discuss-icons.mob-post-static {
    position: absolute;
    right: -6px;
    top: 32px;
    left: auto;
} */
.scroll-sec-feed-msg .mob-profile-right .post-reaction-blk a i {
    margin-right: 5px;
    font-size: 11px;
}
.mob-profile-dotted i {
    font-size: 10px;
}
.scroll-sec-feed-msg  .row_icons_fas .important-icon,  .scroll-sec-feed-msg .row_icons_fas .post-reaction-icon,  .scroll-sec-feed-msg  .row_icons_fas .attachment-icon {
    margin-right: 0px;
    width: 15px;
    height: 15px;
    font-size: 9px;
    margin-bottom: 2px;
    flex: 0 0 15px;
}
.mob-profile-dotted {
    display: block;
}
.sub-detail-responsive   .scroll-sec-feed-msg   .row_icons_fas.ls_box {
    left: 95%;
}
.card-reply .card-right   .like-list  h5 span{ 
    font-size: 14PX;
}
.scroll-sec-feed-msg .mob-profile-right  .post-reaction-blk .like-list  a i {
    margin-right: 10PX;
    font-size: 14px;
    margin-left: 7px;
}
    /* Oct 29 Manisha end */

    .scroll-sec-feed-msg .post-by.user-profile.f-m-cont-main {
      margin: 0px 10px 10px 0px !important;
      padding-right: 0;
  }
.scroll-sec-feed-msg .post-by-right {

  padding-right: 15px;
}
.scroll-sec-feed-msg .important-tag.ps-by-card-header {
  padding: 10px 10px;
}

.scroll-sec-feed-msg .mob-profile-right{    padding-right: 5px;}

.other-reply.summary-main.summary-sidebar-hide {
  border-bottom: 1px solid #e6ebf1;
  padding: 20px 25px 20px 15px;
}
.scroll-sec-feed-msg .row_icons_fas.ls_box {
  left: 95%;
  flex-direction: column;
}

.scroll-sec-feed-msg .mob-profile-dotted i.fas.fa-ellipsis-v {
  MARGIN-RIGHT: 0;
}
.scroll-sec-feed-msg .tag-point-list{
  position: absolute;
  right: 0px;
  top: 22px;
  left: auto;
  flex-direction: column;
  margin-right: 5px;
}
.card-reply-box .comment-con .profile-title-box-main {
  margin-left: 0;
}
.scroll-sec-feed-msg .mob-profile-right .post-reaction-blk a i {
  margin-right: 0px;
  font-size: 11px;
}
.scroll-sec-feed-msg .post-subject-bold {
  font-size: 14px;
}
.ls_box .post-reaction-icon, .ls_box .important-icon {
  margin-right: 0px !IMPORTANT;
}
.scroll-sec-feed-msg .card-reply .card-right h5 span.post-by-time {
  font-size: 12px;}

  .scroll-sec-feed-msg .mob-profile-right .like-list li i {
    margin-right: 5px !important;
    font-size: 12px !important;
    margin-left: 0px !important;
    margin-right: 7PX !important;
}
.scroll-sec-feed-msg .post-reat-list .info-drop a i {
  font-size: 12px;
}

.sub-detail-responsive .assigned-title-info article figcaption p {
  display: flex;
  align-items: center;
}
.sub-detail-responsive .scroll-sec-feed-msg .assigned-title-info article figcaption i.fal.fa-envelope {
  padding-right: 4px;
}
.sub-detail-responsive .scroll-sec-feed-msg .assigned-title-info article figcaption a {
  font-weight: 400;
}
.likeUnlikeBox .post-reat-head a,.post-reat-new .post-reat-head a {
  font-size: 12px;
  padding: 0px 3px;
}
.row_icons_fas.ls_box.edited-icons {
  margin-top: 34px;
  margin-left: -5px;
}

.tabs-wrap .ps-fd-card{    height: calc(100vh - 340px);}

.new-discussion-box .post-by {
  padding-right: 17px;
  padding-left: 10px;
}

.new-discussion-box .editorClassName {
  padding: 9px 15px 25px !important;}

  .show-reply.likeUnlikeBox .card-reply .post-reat-new, .scroll-sec-feed-msg .lastIndex .post-reat-new{    right: 12px;}


  .scroll-sec-feed-msg .summary-sidebar-hide .row_icons_fas.ls_box {
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
}

/* .new-discussion-box {
  margin-left: 10px;
} */
.card-right .new-discussion-box{
  padding:0px;
}
.space-box .wrap-box-right {
  padding-right: 0;
  padding-left: 0px;
}

.sub-menu-right-block.space-box {
  padding: 0;
}

.card-reply .card-right h5 span {
  font-size: 12px !important;
}

.sub-detail-responsive .scroll-sec-feed-msg .assigned-title-info article figcaption i {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0 !important;
}


.space-box .wrap-box-right{padding-top: 0px;}


.sub-menu-left-block button.close-btn-sidebar {
    right: -68px;
    top: 19px !IMPORTANT;
}
.my-tickets-heading-blk.flex-direction.mb-2 {
  padding-left: 0px;
}

.my-tickets-heading-blk .text-md-right.action2-blk{    display: flex;
  justify-content: flex-end;
  flex: 1;}

  .shift-move {
    /* display: none !important; */
  }

  .tickets-new-custom .card-table-custom {
    padding: 10px;}

    .search-filter-div-left .dropdown-comman .btn {
      font-size: 13px;
  }

  .my-tickets-heading-blk .d-flex.align-items-center{    width: 100%;    margin-bottom: 5px;}

  .my-tickets-heading-blk .text-md-right.action2-blk{    flex: initial;}

  .my-tickets-heading-blk .text-md-right.action2-blk {
    margin-left: 0 !important;
    padding-left: 0 !IMPORTANT;
    border-left: 0 !IMPORTANT;
    margin-right: 6px;
}


.system-bot-inner-R-title small.tag {
  margin-bottom: 5px;
}

}
@media only screen and (max-width: 395px ) {
  .form-group-save-cancel .btn+.btn+.btn {
    margin: 0;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 367px) {
  
  .sub-detail-responsive .modal-update .flexible-modal {
    width: 80% !important;
  }
  .sub-detail-responsive   .iframe_modal_full .flexible-modal .body{ 
    height: 200px !important;
  }
  .sub-detail-responsive .modal-update .flexible-modal {
    width: 80% !important;
  }
  .sub-detail-responsive   .iframe_modal_full .flexible-modal .body{ 
      height: 200px !important;
  }
 




}
/* filters scroll responsive css start */

@media only screen and (max-width: 1700px)  { 
  .filter-scroll{min-width: 500px; position: relative;}
  .filter-scroll-inner { max-width: 500px;overflow-x: auto; overflow-y:visible;display: flex; position:static; left:0; top:0px; min-height: 470px; -ms-overflow-style: none; scrollbar-width: none;}
  .filter-scroll-inner::-webkit-scrollbar{display: none;}
  .none_top_scroll {z-index: 11;position: absolute;max-width: 500px;min-width: 500px;overflow-x: auto;height: 15px;top: -32px;display: block;overflow-y: clip;}
  .filter-scroll {
    top: -17px;
  }
  .filter-scroll .btn-arrow {display: block;}
  .filter-scroll-inner {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1500px)  { 
  .filter-scroll{min-width: 300px; position: relative;}
  .filter-scroll-inner { max-width: 300px;overflow-x: auto; overflow-y:visible;display: flex; position:static; left:0; top:0; min-height: 470px; -ms-overflow-style: none; scrollbar-width: none;}
  .filter-scroll-inner::-webkit-scrollbar{display: none;}
  .none_top_scroll {max-width: 400px; min-width: 400px;}
}
@media only screen and (max-width: 1300px)  { 
  .filter-scroll{min-width: 335px; position: relative;}
  .filter-scroll-inner { max-width: 340px;overflow-x: auto; overflow-y:visible;display: flex; position:static; left:0; top:0; min-height: 470px; -ms-overflow-style: none; scrollbar-width: none;}
  .filter-scroll-inner::-webkit-scrollbar{display: none;}
  .none_top_scroll {max-width: 340px;min-width: 340px;}
}




@media only screen and (max-width: 1115px){
  .filter-button-group .add-ticket-blk{margin-left: 8px; margin-top: 10px;}
  .filter-button-group > div + div{margin-left: 4px;}
}

@media only screen and (max-width: 921px){
  .files-export-group{margin-top: 10px;}
  .button-reset{margin-top: 10px;}
}

@media only screen and (max-width: 830px){
  .filter-scroll{min-width: 275px;}
  .filter-scroll-inner{max-width: 275px;}
}
@media only screen and (max-width: 767px){
  .button-reset{margin-top: 0;} 
  .files-export-group{margin-top: 0;}
  .filter-button-group .add-ticket-blk{margin-top: 0;}
  .lecture-detail-top .lecture-detail-top-right{
    display: none !important;
  }  
  main{
      padding-top: 0px !important;
  }
  /* .sub-detail-lft .close-btn-sidebar {
      right: -56px;
      top: -24px;
  } */
  .lecture-detail-top-left{padding-left: 50px;}
}
.export-ticket-button{
  padding-right: 10px !important;
}
@media only screen and (max-width: 687px){
  .filter-button-group .add-ticket-blk{margin-top: 10px;}
}
@media only screen and (max-width: 687px){
  .button-reset{margin-top: 10px;}  
}
@media only screen and (max-width: 410px){
  .filter-scroll{min-width: 188px;}
  .filter-scroll-inner{max-width: 188px;}
}

@media only screen and (max-width: 320px){
  .filter-scroll{min-width: 100px;}
  .filter-scroll-inner{max-width: 100px;}
}

/* filters scroll responsive css end */

@media only screen and (max-width: 767px){
  .frm-group .mb-2.d-flex .uploaded-files { width: 90%;}
}
@media only screen and (max-width: 1280px){
  .table-book-responsive { overflow-x: auto;overflow-y: hidden;padding-bottom: 15px; }
}
@media only screen and (max-width: 1300px){
  .frm-group .mb-2.d-flex .uploaded-files { width: 80%;}
}
@media only screen and (max-width: 1600px){
  .course-gradebook .table-book .table-book-mid .less-items{ overflow: auto;}
  .course-gradebook .table-book .table-book-right .many-items { max-width: fit-content;}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-with-small-second-card .card{margin-bottom: 30px;}
}